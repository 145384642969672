import React from 'react';
import { useAuthentication } from "./AuthUserContext";

import "./Account.scss";
import PasswordField from './PasswordField';

const AccountPage = () => {
  const authUser = useAuthentication()

  return (
    <div id="accountpage">
      <div id="account">
        <h1>Account</h1>
        <div id="divider"></div>
        <div className="field">
            <div className="box">
              <div>
                <h3>Email:</h3>
                <div className="currentValue">{authUser?.email}</div>
              </div>

            </div>
        </div>

        <PasswordField />
      </div>
    </div>
  )
}


export default AccountPage
